<template>
  <div class="list">
    <h3 class="title">{{ type == 1 ? "医院信息列表" : "医院信息" }}</h3>
    <div class="card">
      <div class="top" v-if="type == 1">
        <div class="add-btn" @click="addYy">
          <img src="@/assets/img_07.png" alt="" class="img" />
          新增医院
        </div>
        <div class="btn-del-top" @click="delYyList">删除</div>
        <div class="search-group">
          <div class="input-box">
            <el-input v-model="search.name" placeholder="医院名称"></el-input>
          </div>
          <div class="search-btn" @click="getList">搜索</div>
        </div>
      </div>
      <div class="table">
        <el-table :data="list" style="width: 100%" v-loading="loading" height="456"
          @selection-change="handleSelectionChange" tex>
          >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="title" label="医院名称" width="200" align="center">
          </el-table-column>
          <el-table-column label="医生" width="300" align="center">
            <template slot-scope="scope">
              <div class="btn-group">
                <div class="btn-add" @click="addYs(scope.row.id)">新增医生</div>
                <div class="btn-see" @click="seeYs(scope.row.id)">查看</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="备注" align="center">
            <template slot-scope="scope">
              <div class="desc">
                {{ scope.row.desc }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" align="center" v-if="type == 1">
            <template slot-scope="scope">
              <div class="btn-del-txt" @click="editYy(scope.row)">编辑</div>
              <div class="btn-del-txt" @click="deltYy(scope.row.id)">删除</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom" v-if="type == 1">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="pagination.currentPage" :page-sizes="pagination.sizes" :page-size.sync="pagination.size"
          layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="formYy.id ? '编辑医院' : '新增医院'" :visible.sync="dialogFormVisible" width="700px"
      :before-close="handleClose" :close-on-click-modal="false">
      <div class="add-form">
        <el-form ref="formYy" :model="formYy" label-width="110px" :rules="rules">
          <el-form-item label="医院名称" prop="name">
            <el-input v-model="formYy.name" placeholder="请输入医院名称"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input v-model="formYy.desc" type="textarea" placeholder="请输入备注信息" maxlength="800"></el-input>
          </el-form-item>
          <div class="doctor-item" v-for="(item, index) in formYy.ysList" :key="index">
            <el-button class="del_doctor" type="danger" icon="el-icon-delete" circle
              @click="delYsListForm(index)"></el-button>
            <el-form-item label="医生名称" :prop="'ysList.' + index + '.name'" :rules="rules.name">
              <el-input v-model="item.name" placeholder="请输入医生名称"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="desc">
              <el-input v-model="item.desc" type="textarea" placeholder="请输入备注信息" maxlength="800"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="!formYy.id" type="warning" @click="pushYsList">新增医生</el-button>
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitYy">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="formYs.dId ? '编辑医生' : '新增医生'" :visible.sync="dialogDoctor" width="700px"
      :before-close="handleClose" :close-on-click-modal="false">
      <div class="add-form">
        <el-form ref="formYs" :model="formYs" :rules="rules" label-width="110px">
          <el-form-item label="医生名称" prop="name">
            <el-input v-model="formYs.name" placeholder="请输入医生名称"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input v-model="formYs.desc" type="textarea" placeholder="请输入备注信息" maxlength="800"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDoctor = false">取 消</el-button>
        <el-button type="primary" @click="submitYs">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="医生信息" :visible.sync="dialogDesc" width="900px" :close-on-click-modal="false">
      <div class="table-info">
        <el-table :data="doctorList" style="width: 100%" @selection-change="handleSelectionChangedoctor">
          >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="title" label="医生" width="200">
          </el-table-column>
          <el-table-column label="所属医院" width="200">
            <template slot-scope="scope">
              {{ scope.row.hospital.title }}
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <div class="desc" :style="{ color: '#999' }">
                {{ scope.row.desc }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" align="center">
            <template slot-scope="scope">
              <div :style="{ cursor: 'pointer' }" class="btn-del-txt" @click="editYs(scope.row)">
                编辑
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="btn-del" @click="delYsList">删除</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "list",
  data() {
    return {
      search: {
        name: "",
        yy: "",
        ys: "",
        type: "",
        time: "",
        state: "",
      },
      formYy: {
        id: "",
        name: "",
        desc: "",
        ysList: [
          // {
          //   id: "",
          //   name: "",
          //   desc: "",
          // },
          // {
          //   id: "",
          //   name: "",
          //   desc: "",
          // },
        ],
      },
      formYs: {
        id: "",
        name: "",
        desc: "",
        dId: "",
      },

      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        // desc: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },

      dialogFormVisible: false,
      dialogDoctor: false,
      dialogDesc: false,
      loading: false,
      pagination: {
        sizes: [10, 30, 50, 100],
        size: 10,
        total: 100,
        currentPage: 1,
      },
      list: [],
      doctorList: [],
      multipleSelection: [],
      multipleSelectionDoctor: [],
      type: localStorage.getItem("_type_"),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    pushYsList() {
      this.formYy.ysList.push({
        id: "",
        name: "",
        desc: "",
      });
    },
    delYsListForm(index) {
      this.formYy.ysList.splice(index, 1);
      this.$refs["formYy"].clearValidate();
    },
    editYy(item) {
      this.formYy = {
        id: item.id,
        name: item.title,
        desc: item.desc,
      };
      this.dialogFormVisible = true;
    },
    editYs(item) {
      this.formYs = {
        id: item.hospital.id,
        name: item.title,
        desc: item.desc,
        dId: item.id,
      };
      this.dialogDoctor = true;
    },
    handleSizeChange() {
      this.getList();
    },
    handleCurrentChange() {
      this.getList();
    },
    addYs(id) {
      this.formYs = {
        id,
        name: "",
        desc: "",
        dId: "",
      };
      this.dialogDoctor = true;
    },
    seeYs(id) {
      this.formYs.id = id;
      this.$api.doctor_lists({ hospital_id: id }).then((res) => {
        if (res.code == 200) {
          this.doctorList = res.data;
          this.dialogDesc = true;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1000,
          });
        }
      });
    },
    deltYy(id) {
      this.$confirm("该医院没有所属订单时才能删除。是否确认删除医院?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api
            .hospital_delete({
              hospital_id: id,
            })
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "success",
                  duration: 1000,
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "error",
                  duration: 1000,
                });
              }
            });
        })
        .catch(() => { });
    },
    delYyList() {
      if (this.multipleSelection.length) {
        let arr = this.multipleSelection.map((item) => item.id);
        this.$confirm(
          "该医院没有所属订单时才能删除。是否确认删除医院?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$api
              .hospital_delete({
                hospital_id: arr.join(),
              })
              .then((res) => {
                if (res.code == 200) {
                  this.getList();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                    showClose: true,
                    duration: 1000,
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          })
          .catch(() => { });
      }
    },
    delYsList() {
      if (this.multipleSelectionDoctor.length) {
        let arr = this.multipleSelectionDoctor.map((item) => item.id);
        this.$confirm("是否确定删除医生?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$api
              .doctor_delete({
                doctor_id: arr.join(),
              })
              .then((res) => {
                if (res.code == 200) {
                  this.seeYs(this.formYs.id);
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                    showClose: true,
                    duration: 1000,
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          })
          .catch(() => { });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChangedoctor(val) {
      this.multipleSelectionDoctor = val;
    },
    getList() {
      this.loading = true;
      this.$api
        .hospital_lists({
          page: this.pagination.currentPage,
          limit: this.pagination.size,
          key: this.search.name,
        })
        .then((res) => {
          this.loading = false;
          this.pagination.total = res.data.count_all;
          this.list = res.data.lists;
        });
    },
    submitYy() {
      this.$refs["formYy"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          if (this.formYy.id) {
            this.$api
              .hospital_update({
                hospital_id: this.formYy.id,
                title: this.formYy.name,
                desc: this.formYy.desc,
              })
              .then((res) => {
                loading.close();
                if (res.code == 200) {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "success",
                    duration: 1000,
                  });
                  this.formYy = {
                    id: "",
                    name: "",
                    desc: "",
                  };
                  this.getList();
                  this.$refs["formYy"].resetFields();
                  this.dialogFormVisible = false;
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          } else {
            this.$api
              .hospital_create({
                title: this.formYy.name,
                desc: this.formYy.desc,
              })
              .then((res) => {
                if (res.code == 200) {
                  if (this.formYy.ysList.length) {
                    let arr = [];
                    this.formYy.ysList.forEach((item) => {
                      arr.push(
                        new Promise((resolve, reject) => {
                          this.$api
                            .doctor_create({
                              hospital_id: res.data.id,
                              title: item.name,
                              desc: item.desc,
                            })
                            .then((res) => {
                              resolve(res);
                            })
                            .catch((e) => {
                              reject(e);
                            });
                        })
                      );
                    });
                    Promise.all(arr).then((res) => {
                      loading.close();
                      this.$message({
                        showClose: true,
                        message: "创建成功",
                        type: "success",
                        duration: 1000,
                      });
                      this.formYy = {
                        id: "",
                        name: "",
                        desc: "",
                      };
                      this.getList();
                      this.$refs["formYy"].resetFields();
                      this.dialogFormVisible = false;
                    });
                  } else {
                    loading.close();
                    this.$message({
                      showClose: true,
                      message: res.msg,
                      type: "success",
                      duration: 1000,
                    });
                    this.formYy = {
                      id: "",
                      name: "",
                      desc: "",
                    };
                    this.getList();
                    this.$refs["formYy"].resetFields();
                    this.dialogFormVisible = false;
                  }
                } else {
                  loading.close();
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addYy() {
      this.formYy = {
        id: "",
        name: "",
        desc: "",
        ysList: [],
      };
      this.dialogFormVisible = true;
    },
    submitYs() {
      this.$refs["formYs"].validate((valid) => {
        if (valid) {
          if (this.formYs.dId) {
            this.$api
              .doctor_update({
                hospital_id: this.formYs.id,
                title: this.formYs.name,
                desc: this.formYs.desc,
                doctor_id: this.formYs.dId,
              })
              .then((res) => {
                if (res.code == 200) {
                  // localStorage.setItem("_token_", res.data.token);
                  // this.$router.push({
                  //   path: "/",
                  // });
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "success",
                    duration: 1000,
                  });
                  this.seeYs(this.formYs.id);
                  this.formYs = {
                    id: "",
                    name: "",
                    desc: "",
                    dId: "",
                  };
                  this.$refs["formYs"].resetFields();
                  this.dialogDoctor = false;
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          } else {
            this.$api
              .doctor_create({
                hospital_id: this.formYs.id,
                title: this.formYs.name,
                desc: this.formYs.desc,
              })
              .then((res) => {
                if (res.code == 200) {
                  // localStorage.setItem("_token_", res.data.token);
                  // this.$router.push({
                  //   path: "/",
                  // });
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "success",
                    duration: 1000,
                  });
                  this.formYs = {
                    id: "",
                    name: "",
                    desc: "",
                    dId: "",
                  };
                  this.getList();
                  this.$refs["formYs"].resetFields();
                  this.dialogDoctor = false;
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose(done) {
      this.formYy = {
        name: "",
        desc: "",
      };
      if (this.$refs["formYy"]) {
        this.$refs["formYy"].resetFields();
      }
      this.formYs = {
        id: "",
        name: "",
        desc: "",
      };
      if (this.$refs["formYs"]) {
        this.$refs["formYs"].resetFields();
      }
      done();
    },
  },
};
</script>

<style lang="scss">
.list {
  .el-table th {
    background: #eef4f1;
    color: #333333;
  }

  .btn-del {
    width: 70px;
    line-height: 33px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-top: 30px;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background: #2d8cf0;
    border-color: #2d8cf0;
  }

  .card {
    .input-box {
      .el-input__inner {
        width: 130px;
        margin-right: 10px;
      }

      .el-input {
        width: unset;
      }
    }

    .top {
      .el-input__inner {
        width: 170px;
        margin-right: 10px;
      }

      .el-input__suffix {
        right: 10px;
      }
    }

    .big-box {
      .el-input__inner {
        width: 280px;
        margin-right: 10px;
      }

      .el-date-editor .el-range-separator {
        padding: 0;
      }
    }

    .small-box {
      .el-input__inner {
        width: 130px;
        margin-right: 10px;
      }
    }

    .bottom {
      .el-pager {
        .number {
          border: 1px solid #ebebeb;
          margin-right: 4px;

          &:hover {
            color: #2d8cf0;
          }

          &.active {
            background: #2d8cf0;
            border: 1px solid #2d8cf0;
            color: #ffffff;
          }
        }
      }
    }

    .el-table th {
      background: #eef4f1;
      color: #333333;
    }
  }

  .add-form {
    max-height: 520px;
    overflow-y: auto;

    .doctor-item {
      position: relative;

      .del_doctor {
        position: absolute;
        right: 30px;
      }
    }

    .el-form-item {
      margin-bottom: 22px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .el-form-item--medium .el-form-item__content {
      margin-left: 0 !important;
    }

    .el-input__inner {
      width: 420px;
    }

    .upload-btn {
      width: 110px;
      height: 110px;
      background: #f1f9f5;
      border: 1px dashed #9a9c9b;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        width: 26px;
        height: 26px;
        margin-bottom: 17rpx;
      }
    }

    .y-wei {
      width: 420px;
      display: flex;
      flex-wrap: wrap;

      .box {
        display: flex;
        padding-right: 8px;
        border-bottom: 1px dashed #979797;
        border-right: 1px dashed #979797;
        padding-bottom: 10px;

        &:nth-child(2n) {
          padding-right: 0;
          border-right: none;
          padding-left: 10px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-bottom: none;
          padding-bottom: 0;
          padding-top: 10px;
        }

        .item {
          width: 23px;
          line-height: 27px;
          background: #eef4f1;
          border-radius: 5px;
          margin-right: 2px;
          cursor: pointer;
          text-align: center;
          font-size: 16px;
          color: #333333;

          &.active {
            background: #2d8cf0;
            color: #fff;
          }
        }
      }
    }
  }

  .el-button--primary {
    background: #2d8cf0 !important;
    border-color: #2d8cf0 !important;
  }

  .el-textarea__inner {
    resize: none;
    width: 420px;
    height: 150px;
  }
}
</style>
<style lang="scss" scoped>
.list {
  .title {
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 24px;
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 30px 30px 40px;
    position: relative;
    z-index: 1;

    .top {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      position: relative;
      .search-group{
        position: absolute;
        right: 10px;
        display: flex;
      }
      .search-btn {
        width: 90px;
        line-height: 36px;
        background: #2d8cf0;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        margin-right: 30px;
        cursor: pointer;
      }

      .add-btn {
        width: 110px;
        height: 36px;
        background: #2d8cf0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        cursor: pointer;

        i.el-icon-plux {}
      }

      .btn-del-top {
        width: 110px;
        height: 36px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #2d8cf0;
        border: 1px solid #2d8cf0;
        cursor: pointer;
        margin-left: 15px;
      }
    }

    .table {
      margin-bottom: 35px;

      .btn-group {
        display: flex;
        justify-content: center;

        .btn-add {
          cursor: pointer;
          width: 100px;
          line-height: 36px;
          text-align: center;
          background: #2d8cf0;
          border-radius: 5px;
          color: #fff;
          margin-right: 10px;
        }

        .btn-see {
          cursor: pointer;
          width: 100px;
          line-height: 36px;
          background: #ffffff;
          border: 1px solid #9ce8c2;
          border-radius: 5px;
          color: #2d8cf0;
        }
      }

      .desc {
        color: #999999;
        text-align: left;
      }

      .btn-del-txt {
        color: #333333;
        cursor: pointer;
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
    }
  }
}
</style>